<template>
	<div id="app">
		<test></test>
		<img src="./images/banner6@1x.png" style="width: 100%;" />
		<div class="blank">
			
		</div>
	    <div class="tab_main">
	    	<ul class="tab-tit">
	    	    <li v-for="(title,index) in tabTitle.slice(0,3)" :key="index">
					<span  @click="cur=index" :class="{actives:cur==index}">{{title.categoryTitle}}</span>				
				</li>
				
	    	</ul>
			<div class="cont_li">
				<div class="tab-content" :class="{dis:cur==0}">				    
					<div v-for="(item,index) in list1" :key="index" @click="detail(item.id)" >
						<img :src="item.contentImg"  v-if="item.contentImg!='no image'" />
						<div id="div">
							<div id="txt">
								<span>{{item.contentTitle}}</span>
								<i>{{item.contentDatetime.substring(0,10)}}</i>
							</div>
							<p>{{item.contentDescription}}</p>							
						</div>												
					</div>								
				</div>
				<div class="tab-content" :class="{dis:cur==1}">
					<div v-for="(item,index) in list2" :key="index" @click="detail(item.id)" >
						<img :src="item.contentImg"  v-if="item.contentImg!='no image'" />
						<div id="div">
							<div id="txt">
								<span>{{item.contentTitle}}</span>
								<i>{{item.contentDatetime.substring(0,10)}}</i>
							</div>
							<p>{{item.contentDescription}}</p>							
						</div>
					</div>								
				</div>
				<div class="tab-content" :class="{dis:cur==2}">
					<div v-for="(item,index) in list3" :key="index" @click="detail(item.id)" >
						<img :src="item.contentImg" v-if="item.contentImg!='no image'" />
						<div id="div">
							<div id="txt">
								<span>{{item.contentTitle}}</span>
								<i>{{item.contentDatetime.substring(0,10)}}</i>
							</div>
							<p>{{item.contentDescription}}</p>							
						</div>
					</div>								
				</div>
<!-- 				<div class="tab-content" :class="{dis:cur==3}">
					<div v-for="(item,index) in list4" :key="index" @click="detail(item.id)" >
						<img :src="item.contentImg"  v-if="item.contentImg!='no image'" />
						<div id="div">
							<div id="txt">
								<span>{{item.contentTitle}}</span>
								<i>{{item.contentDatetime.substring(0,10)}}</i>
							</div>
							<p>{{item.contentDescription}}</p>							
						</div>
					</div>								
				</div> -->
			</div>	    	
	    </div>
		<foot></foot>
	</div>
</template>

<script>
import test from './components/test.vue'
import foot from './components/foot.vue'
export default {
	components: {
		test,
		foot	
	},
	data(){
		return{
			list1:[],
			list2:[],
			list3:[],
			list4:[],
			tabTitle: [],
			cur: 0 //默认选中第一个tab
		}	
	},
	created(){
		// if (location.href.indexOf("#reloaded") == -1) {
		// 	  location.href = location.href + "#reloaded";
		// 	  location.reload();
		// }
		this.send()
	},
	
	methods:{
		detail(id){
			this.$router.push({
			     path: '/Notice_detail',
			     query: {
			       id: id,
			     }
			
			})
		},
		send(){
			var that = this
			this.$axios.post('/cms/returnAll/officialWebsite')
			.then(function (res) {
				console.log(res);
				that.tabTitle = res.data.categoryList[3].categoryChildrenList
				that.list1 = res.data.categoryList[3].categoryChildrenList[0].contentList
				that.list2 = res.data.categoryList[3].categoryChildrenList[1].contentList
				that.list3 = res.data.categoryList[3].categoryChildrenList[2].contentList
				that.list4 = res.data.categoryList[3].categoryChildrenList[3].contentList
			})

		}
	}
}

</script>

<style type="text/css">
	.tab-content>div{
		margin-top: 10px;
		padding-left: 5%;
		margin-bottom: 6%;
		display: flex;
		justify-content: center;
	}
	.tab-content>div img{
		flex: 1;
		width: 130px;
		height: 90px;
	}
	.tab-content>div p{
		font-size: 13px;
		color: #666;
		text-align: left;
		margin-top: 10px;
		text-overflow: ellipsis; display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
	}
	.tab-content #div #txt{
		display: flex;
		justify-content: center;
	}
	.tab-content #div{
		flex: 9;	
		padding:5px 10px;
		cursor: pointer;
	}
	.tab-content span{
		flex: 6;
		text-align: left;

	}
	.tab-content i{
		text-align: right;
		flex: 2;
		margin-top: 0;
	}
	.cont_li{
		flex: 6;
	}
	.dis{
		display: block !important;
	}
	
	
	.tab-content a{
		color: #333;
		display: block;
	}
	.blank{
		height: 850px;
	}
	.tab-tit{
		flex: 1.5;
	}
	.tab-content{
		
		display: none;
	}
	.tab_main{
		padding: 3% 4% 3% 0;
		border-radius: 5px;
		box-shadow: 0 5px 15px #959595;
		background-color: #fff;
		width: 65%;		
		position: absolute;
		top: 50%;
		z-index: 8;
		left: 50%;
		transform: translateX(-50%);
		display: flex;
		justify-content: center;
	}
	ul li {
		margin: 0;
		padding: 0;
		list-style: none;
	}
	.tab-tit li{
		padding: 10px 0;
		text-align: center;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.tab-tit li .tab-content{
		text-align: left;
		padding-left: 5%;
		flex: 5;
	} 
	.tab-tit li span{
		flex: 1;
	}
 /* 点击对应的标题添加对应的背景颜色 */
	.tab-tit .actives{
		border-right: 2px solid #EA0029;
		color: #EA0029;
	}
	/* .tab-content div{
		float: left;
		width: 25%;
		line-height: 100px;
		text-align: center;
	} */
</style>
